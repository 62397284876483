<template>
  <div>
    <div class="compont_p">我的积分流水</div>

    <!-- <div class="flex_row top">
      <div class="left">
        <p class="c6">积分余额</p>
        <p class="price">￥{{ tableData1[0].jfye }}</p>
      </div>
      <div class="right">
        <p class="mb_20 c3">最近三个月积分统计</p>
        <el-table :data="tableData1" :cell-style="tableRowStyle">
          <el-table-column prop="type" label="" align="center" />
          <el-table-column prop="jfls1" label="每日登录" align="center" />
          <el-table-column prop="jfls2" label="注册赠送" align="center" />
          <el-table-column prop="jfls3" label="消费累计" align="center" />
          <el-table-column prop="jfls4" label="兑换礼品" align="center" />
        </el-table>
      </div>
    </div> -->

    <el-form :inline="true" style="margin-top: 20px">
      <el-form-item label="积分类型:">
        <el-select v-model="jflx" placeholder="请选择" clearable>
          <el-option label="全部" value=""> 全部</el-option>
          <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="发生时间:">
        <el-date-picker v-model="time" type="daterange" @change="changeTime" value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"> </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" class="custombtn" @click="getPoints">筛选</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column type="index" width="100" label="序号" align="center" />
      <el-table-column prop="title" label="积分类型" align="center" />
      <el-table-column prop="points" label="积分数量" align="center" />
      <!-- <el-table-column prop="ffzt" label="发放状态" align="center" /> -->
      <!-- <el-table-column prop="bz" label="备注信息" align="center" /> -->
      <el-table-column prop="date" label="发生时间" align="center" />
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      tableData1: [],
      options: [],
      jflx: "",
      time: "",
      total: 0,
      page: 1,
      start_time: "",
      end_time: "",
      page_size: 10,
    };
  },
  created() {
    this.getPoints();
    this.$api("account.getPointsType").then((res) => {
      this.options = res.data;
      // this.tableData1.push(res.data.ct);
      // this.tableData1[0].type = "普通积分";
    });
  },
  methods: {
    getPoints() {
      console.log(this.time);
      if (this.time && this.time.length > 0) {
        console.log(111);
        let Atime = Date.parse(new Date(this.time[0])) / 1000;
        let Btime = Date.parse(new Date(this.time[1])) / 1000;
        if (Atime > Btime) {
          this.end_time = Atime;
          this.start_time = Btime;
        } else {
          this.end_time = Btime;
          this.start_time = Atime;
        }
      }
      this.$api("account.getPoints", {
        scoreType: this.jflx,
        time_start: this.start_time,
        time_end: this.end_time,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    tableRowStyle({ columnIndex }) {
      if (columnIndex !== 0) {
        return "color:#0abc64;!important;";
      }
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getPoints();
    },
    changeTime(e) {
      console.log("-----", e);
      if (!e) {
        this.end_time = "";
        this.start_time = "";
      }
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getPoints();
    },
  },
};
</script>

<style lang="less" scoped>
.top {
  height: 150px;
  align-items: center;
}
.left {
  width: 215px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .price {
    font-size: 20px;
    font-weight: bold;
    color: @themeColor;
    margin-top: 20px;
  }
}
.right {
  flex: 1;
}
</style>
